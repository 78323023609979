<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  .ck-editor__editable{
    min-height: 185px;
  }
  /deep/.el-select.blueBorder .el-input__inner {
    border-color: rgb(23, 118, 210);
    height: 30px;
  }
  /deep/.el-input__inner{
    height: 30px;
    line-height: 30px;
    border-color: rgb(23, 118, 210);
  }
</style>
<style>
.col-sm-3{
  max-width: 22% !important;
}
</style>
<script>
  import Layout from "@/views/layouts/main";
  import {
    userLoginList,
    userLoginListExport
  } from "@/api/log/log.js";

  /**
   * 系统管理--日志管理--用户登录日志
   */
  export default {
    components: {
      Layout,



    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "系统管理",
            active: true
          }
        ],
        isActive: 1,
        pathArr: [{
          index: 1,
          url: "/admin/systemLog/userLogin",
          name: "登录日志",
        },
          {
            index: 2,
            url: "/admin/systemLog/userOperation",
            name: "用户操作日志",
          },
          {
            index: 3,
            url: "/admin/systemLog/enrollCheck",
            name: "报名审核日志",
          },
          {
            index: 4,
            url: "/admin/systemLog/scoreSet",
            name: "成绩管理日志",
          },
          {
            index: 5,
            url: "/admin/baseSubject",
            name: "备份日志",
          },

        ],
        query:{
          keyword: "",
          database: "",
          begDate: "",
          endDate: "",
          pageNum: 1,
          pageSize: 20
        },
        logList: [],
        // 当前页
        currentPage: 1,
        // 每页显示
        perPage: 20,
        // 总数
        total: 0
      }
    },
    mounted() {
      this.isActive = window.location.pathname
      this.getList();
    },
    computed: {
      rows() {
        return this.logList.length;
      },
    },
    methods: {
      /** 查询登录日志列表 */
      getList() {
        this.query.pageNum = this.currentPage
        this.query.pageSize = this.perPage
        userLoginList(this.query).then(res => {
          if (res.status) {
            this.logList = res.data
            this.total = res.total;
          }
        });
      },
      searchClick() { //搜索时先将分页的页码归1
        this.currentPage = 1; //当前页				
        this.getList() //渲染数据的函数
      },
      exportExcel() {
        userLoginListExport(this.query).then(res => {
          console.log(res)
          if (!res.data) {
            return
          }
          let url = window.URL.createObjectURL(new Blob([res.data]))
          let link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', 'userLoginLog.xlsx')

          document.body.appendChild(link)
          link.click()
        })
      },
      handleSizeChange(val) {
        this.perPage = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.currentPage = val
        this.getList();
      },
    }
  };
</script>

<template>
  <Layout>
    <!-- <LogHeader :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box flexList justify-content-between">
          <div class="flexList col-4 p-0">
            <a :href="obj.url" class="col-sm-3 p-0 mr-3" v-for="obj in pathArr" :key="obj.index">
              <div class="base-header-box " :class="{active:isActive==obj.url}">{{obj.name}}</div>
            </a>
          </div>
          <div class="page-title-right">
            <b-breadcrumb :items="items" class="m-0"></b-breadcrumb>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 500px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <el-select class=" mr-2 blueBorder" style="width: 15%;" placeholder="选择日志库" v-model="query.database"
                           size="small">
                  <el-option value="" label="所有日志库"></el-option>
                  <el-option value="master" label="主数据库"></el-option>
                  <el-option value="slave" label="从数据库"></el-option>
                </el-select>

                <el-date-picker v-model="query.begDate" class="w-10 mr-2" type="datetime" size="small" placeholder="选择开始时间"> </el-date-picker>
                <el-date-picker v-model="query.endDate" class="w-10 mr-2" type="datetime" size="small" placeholder="选择结束时间"> </el-date-picker>
                <input placeholder="输入关键字" v-model="query.keyword" class="h30 form-control border-blue mr-2 w-15" maxlength="50"
                 />

                <button type="button" class="btn btn-info h30 flexList mr-2 " @click="searchClick"><i
                    class="iconfont mr-2 icon-mb-search"></i>查询</button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab" @click="exportExcel"><i class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <!-- <div class="border-blue export-tab"><i class="iconfont icon-data mr-2"></i>dbf</div> -->
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table light-table table-hover  table-bordered">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 4%;"> 序号</th>
                    <th style="width: 12%;">用户名id</th>
                    <th style="width: 5%;">姓名</th>
                    <th >登录来源 </th>
                    <th style="width: 7%;">登录类别 </th>
                    <th style="width: 7%;">登录状态 </th>
                    <th style="width: 10%;">操作系统 </th>
                    <th style="width: 10%;">浏览器</th>
                    <th style="width: 11%;">时间</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj, index) in logList" :key="index">
                    <td>{{index+1+(currentPage-1)*perPage}}</td>
                    <td></td>
                    <td>{{obj.userName}}</td>
                    <td>{{obj.ipaddr}}</td>
                    <td></td>
                    <td>{{obj.status == '1' ? '成功' : '失败'}}</td>
                    <td>{{obj.os}}</td>
                    <td>{{obj.browser}}</td>
                    <td>{{obj.loginTime}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right d-flex">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                             :current-page.sync="currentPage" :page-size="perPage"
                             layout="total, sizes,jumper, prev, pager, next" :total="total">
              </el-pagination>
            </div>

          </div>
        </div>
      </div>


    </div>
    <!-- 弹窗开始 -->


    <!-- 弹窗结束 -->
  </Layout>
</template>
